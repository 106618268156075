<template>
    <TheLayoutApp :title="title">
        <AppContainer
            size="small"
            variant="fullCentered"
        >
            <AppPage class="grow-0">
                <AppHeader>
                    <AppTitle size="xxlarge" class="font-black">
                        {{ title }}
                    </AppTitle>
                </AppHeader>

                <AppArticle size="large">
                    {{ description }}
                </AppArticle>
            </AppPage>
        </AppContainer>
    </TheLayoutApp>
</template>

<script lang="ts">
import TheLayoutApp from "@/Layouts/TheLayoutApp.vue";
import AppContainer from "@/Components/Shared/container/AppContainer.vue";
import AppPage from "@/Components/Shared/page/AppPage.vue";
import AppHeader from "@/Components/Shared/header/AppHeader.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppArticle from "@/Components/Shared/article/AppArticle.vue";
import { computed, defineComponent } from "vue";

export default defineComponent({
    components: {
        AppArticle,
        AppTitle,
        AppHeader,
        AppPage,
        AppContainer,
        TheLayoutApp,
    },
    props: {
        status: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const title = computed(() => {
            return {
                503: "503: Service niet beschikbaar",
                500: "500: Server Error",
                404: "404: Pagina niet gevonden",
                403: "403: Verboden toegang",
            }[props.status];
        });
        const description = computed(() => {
            return {
                503: "We zijn bezig met wat onderhoud. Probeer het later nog een keer.",
                500: "Oeps, iets ging er fout bij onze servers.",
                404: "De pagina die je probeert te bezoeken is niet (meer) beschikbaar.",
                403: "Pardon, jij hebt mogelijk geen toegang tot deze pagina.",
            }[props.status];
        });

        return {
            title,
            description,
        };
    },
});
</script>
